<template>
  <div class="wall-of-love-container">
    <iframe ref="wallFrame" id="wall-of-love" :src="wallUrl" title="Wall of Love" frameborder="0" scrolling="no"
      width="100%" style="min-height: 400px; overflow: hidden;"></iframe>
  </div>
</template>

<script>
export default {
  name: "WallOfLove",
  data() {
    return {
      wallUrl: "https://senja.io/p/radixplore/6u8Y5dm?hideNavigation=true&embed=true",
    };
  },
  mounted() {
    // Dynamically load the iframeResizer script
    const script = document.createElement("script");
    script.src = "https://widget.senja.io/js/iframeResizer.min.js";
    script.async = true;
    script.onload = () => {
      console.log("iframeResizer script loaded");

      // Ensure iFrameResize exists before calling it
      if (window.iFrameResize) {
        window.iFrameResize(
          {
            log: false,
            checkOrigin: false,
            heightCalculationMethod: "bodyScroll", // Ensures dynamic height
          },
          this.$refs.wallFrame
        );
      } else {
        console.error("iframeResizer is not available.");
      }
    };
    document.body.appendChild(script);
  },
};
</script>

<style scoped>
.wall-of-love-container {
  max-width: 100%;
  overflow: hidden;
}

iframe {
  width: 100%;
  min-height: 400px;
}
</style>
