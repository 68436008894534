<template>
  <div class="hero-sass hero-swiper-btn" id="home">
    <div class="container_f1">
      <div class="swiper-wrapper">
        <swiper :options="swiperOption">
          <div id="home" class="swiper-slide" :style="bgImg">

            <div class="hero-item text-left">
              <div>
                <div class="tagline">
                  AI-Powered Mineral Exploration
                </div>
                <h1 class="h1 hero-content-title" style="color: white; text-shadow: 2px 2px #000;">
                  Unlock Hidden Mineral
                  Deposits in Minutes,
                  Not Months
                </h1>
                <h2 class="hero-content-subtitle" style="color: #d3d3d3">
                  Transform unstructured geological data into discoveries with
                  our purpose-built AI platform that analyzes 200 years of
                  exploration history across 50 million pages.
                </h2>
                <div>
                  <div class="d-flex gap-3 no-wrap-spacing" :style="{ width: '100vw' }">

                    <router-link :to="{ path: 'signup' }" class="mt-1 btn mr-3">
                      Start Exploring Now
                    </router-link>

                    <div class="mt-1 btn" @click="$bvModal.show('video-modal')">
                      Watch Demo
                    </div>
                  </div>

                  <FunFactOne />
                  <!-- <span class="btn-icon  ml-0 mr-2"></span> <a href="mailto:contactus@radixplore.com">Request A Demo</a> -->
                </div>
                <!-- <div class="hero-content">
                                <h2 class="h1 hero-content-title font-weight-light" style="color: white; text-shadow: 2px 2px #000;">What will you discover today?</h2>
                                <h6 class="hero-content-subtitle" style="color: #d3d3d3;">Transform the way you search and find valuable insights <br/> in unstructured data with RadiXplore's AI-powered platform.</h6>
                                <div class="btn">
                                    <span class="btn-icon  ml-0 mr-2"></span> <a href="mailto:contactus@radixplore.com">Request A Demo</a>
                                </div>
                            </div> -->
              </div>
            </div>
          </div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import FunFactOne from "../components/FunFactOne";

export default {
  components: {
    FunFactOne,
  },
  data() {
    return {
      bgImg: {
        backgroundImage: `url(${require("../assets/img/bg/website-hero.webp")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      },
      swiperOption: {
        speed: 1500,
        loop: true,
        effect: "fade",
        autoplay: false,
        spaceBetween: 30,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variabls.scss";
@import "../assets/scss/elements/hero-sass.scss";

.hero-content-title {
  width: 50%;
  font-family: 'Inter';
  font-weight: 900;

  // font-size: 4rem;
  @media only screen and (max-width: 479px) {
    // font-size: 2rem;
    width: 90vw;
  }
}

.hero-content-subtitle {
  font-size: 18px;
  width: 45%;

  @media only screen and (max-width: 479px) {
    width: 90vw;
  }
}

.tagline {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  border: 1px solid white;
  width: 230px;
  padding: 2px 4px;
  color: white;
  margin-bottom: 3rem;
  font-weight: 500;
  text-align: center;
  font-size: 90%;
}
</style>
