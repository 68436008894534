<template>
    <b-row class="mt-120" :style="{ width: '100vw' }">
        <b-col cols="4" md="2" class="wow move-up fun-fact-wrapper">
            <div class="fun-fact--one">
                <h5 class="fun-fact__title"></h5>
                <div class="fun-fact__count counter">
                    50M+
                    <!-- <ICountUp
                        :endVal="50000000"
                    /> -->
                </div>
                <span class="fun-fact__text">Pages Analyzed</span>
            </div>

        </b-col>
        <b-col cols="4" md="2" class="wow move-up fun-fact-wrapper">
            <div class="fun-fact--one">
                <h5 class="fun-fact__title"></h5>
                <div class="fun-fact__count counter">
                    200
                    <!-- <ICountUp
                        :endVal="200"
                    /> -->
                </div>
                <span class="fun-fact__text">Years of History</span>
            </div>
        </b-col>
        <b-col cols="4" md="2" class="wow move-up fun-fact-wrapper">
            <div class="fun-fact--one">
                <h5 class="fun-fact__title"></h5>
                <div class="fun-fact__count counter">
                    3
                    <!-- <ICountUp
                        :endVal="3"
                    /> -->
                </div>
                <span class="fun-fact__text">Continents Covered</span>
            </div>
        </b-col>
        <b-col cols="4" md="2" class="wow move-up fun-fact-wrapper">
            <div class="fun-fact--one">
                <h5 class="fun-fact__title"></h5>
                <div class="fun-fact__count counter">
                    Minutes
                    <!-- <ICountUp
                        :endVal="3"
                    /> -->
                </div>
                <span class="fun-fact__text">Not Months</span>
            </div>
        </b-col>
    </b-row>
</template>

<script>
// import ICountUp from 'vue-countup-v2';
export default {
    name: 'FunFactOne',
    // components: {
    //     ICountUp
    // },
    data() {
        return {
            delay: 300
        };
    }
};
</script>

<style scoped>
.fun-fact-wrapper {
    margin: 0 1rem 1rem 1rem;
    border: 0.5px rgba(255, 255, 255, 0.5) solid;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 1.5rem 1rem;
    width: 220px;
}
</style>