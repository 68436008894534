<template>
  <div class="box-image-area section-space--pt_120 section-space--pb_20 theme_bg-2 py-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title text-center">
            <h3 class="section-title text-center mb-4">Features</h3>
          </div>
        </div>
      </div>
      <b-tabs small nav-class="tabs-scroll" id="tabs" :value="activeTab">
        <b-tab v-for="(tab, index) in tabs" :key="index" :id="'tab-' + index" :title="tab.title">
          <template #title>
            <div class="d-flex flex-column align-items-center">
              <p v-html="tab.heading"></p>
            </div>
          </template>
          <div class="py-3">
            <b-row class="flex-column flex-md-row">
              <b-col cols="12" lg="8">
                <div class="image-container">
                  <img class="features-img" :src="require(`@/assets/img/rx-features/${tab.image}`)" :alt="tab.alt" />
                </div>
              </b-col>
              <b-col cols="12" lg="4">
                <div class="feature-details content">
                  <div v-if="tab.details && tab.details.length">
                    <div v-for="(detail, detailIndex) in tab.details" :key="detailIndex" class="detail-item">
                      <h6 class="heading" :style="{ 'font-size': '16px' }">{{ detail.title }}</h6>
                      <div class="text" :style="{ 'font-size': '15px', 'line-height': 1.6 }">{{ detail.subtitle }}</div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>

          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import {
  BIconMap,
  BIconSearch,
  BIconClipboardData,
  BIconBook,
  BIconEye,
  BIconPencil,
  BIconGraphUp,
  BIconGrid,
  BIconGlobe2,
} from "bootstrap-vue";
export default {
  name: "Tabs",
  data() {
    return {
      activeTab: 0,
      tabs: [
        {
          title: "RadixSearch",
          icon: BIconSearch,
          heading: "RadixSearch",
          image: "RadixSearch.webp",
          alt: "RadixSearch",
          details: [
            {
              title: 'Search Without Limits',
              subtitle: 'Find key insights in handwritten, scanned, or poorly digitized documents, overcoming barriers like non-OCR’d text and low-quality images.'
            },
            {
              title: 'Intelligent Contextual Results',
              subtitle: 'RadixSearch’s NLP-powered engine understands geological terms, synonyms, and word variations to deliver precise and meaningful search results.'
            },
            {
              title: 'Error-Tolerant Searching',
              subtitle: 'Handle misspelled words or inconsistencies in legacy data seamlessly, so you never miss critical information due to human or historical errors.'
            }
          ]
        },
        {
          title: "Nerd Search",
          icon: BIconMap,
          heading: "Nerd Search",
          image: "NerdSearch.webp",
          alt: "Nerd Search",
          details: [
            {
              title: 'Visualize Deposits with Heatmaps',
              subtitle: 'Cut through the noise to identify high-potential areas by transforming geological text into mineral heatmaps, helping you locate hidden deposits without traditional data inputs.'
            },
            {
              title: 'Custom Geological Models',
              subtitle: 'Build text signatures for any deposit type to search for specific geological models in vast, unstructured datasets with unmatched precision.'
            },
            {
              title: 'Uncover What Others Miss',
              subtitle: 'Reveal indirect evidence of mineral deposits hidden in historical reports by leveraging advanced query syntax and AI-driven pattern recognition.'
            }
          ]
        },
        {
          title: "Assay Finder",
          icon: BIconClipboardData,
          heading: "Assay Finder",
          image: "AssayFinder.webp",
          alt: "Assay Finder",
          details: [
            {
              title: 'Instant Search for Assay Grades in Unstructured Data',
              subtitle: 'No digitization needed — Assay Finder identifies assay values directly from reports, maps, and geological sections, making historic data instantly searchable.'
            },
            {
              title: 'Advanced AI-Powered Assay Detection',
              subtitle: 'Identify precise assay ranges — even when buried within sentences, images, or complex reports. Find grades like 10-20 g/t gold associated with geological features like quartz veins.'
            },
            {
              title: 'Years of Work, Done in Seconds',
              subtitle: 'Stop wasting time manually digitizing assays. Assay Finder accelerates historical data discovery, unlocking insights in seconds instead of years.'
            }
          ]
        },
        {
          title: "RadixEye",
          icon: BIconEye,
          heading: "RadixEye",
          image: "RadixEye.webp",
          alt: "RadixEye",
          details: [
            {
              title: 'Effortless Image and Table Search',
              subtitle: 'Explore categorized visual data like core images, maps, and thin sections, and find the right visuals faster than ever.'
            },
            {
              title: 'AI-Driven Image Recognition',
              subtitle: 'Use RadixEye’s ZeroShot model to search for images without text—simply describe what you’re looking for, and find results instantly.'
            },
            {
              title: 'Visual Pattern Analysis',
              subtitle: 'Upload an image to discover visually similar data, enabling better pattern recognition and more informed decision-making.'
            }
          ]
        },
        {
          title: "RadixSuggest",
          icon: BIconBook,
          heading: "RadixSuggest",
          image: "RadixSuggest.webp",
          alt: "RadixSuggest",
          details: [
            {
              title: 'Discover New Ideas',
              subtitle: 'Broaden your research by uncovering highly related words or concepts you didn’t know about, based on your selected dataset and region.'
            },
            {
              title: 'Explore Regional Contexts',
              subtitle: 'Get suggestions tailored to your area of interest, ensuring relevance to your geological challenges and exploration goals.'
            },
            {
              title: 'Accelerate Knowledge Discovery',
              subtitle: 'Reduce research gaps by effortlessly finding relationships between topics, enabling informed decision-making and deeper exploration.'
            }
          ]
        },
        {
          title: "RadixGPT",
          icon: BIconPencil,
          heading: "RadixGPT",
          image: "RadixGPT.webp",
          alt: "RadixGPT",
          details: [
            {
              title: 'Geologist-Grade Answers, Not Just AI Responses',
              subtitle: 'RadixGPT is built for geologists, delivering structured, domain-specific insights—not generic AI summaries.'
            },
            {
              title: 'Precision Search with Source Transparency',
              subtitle: 'Unlike broad AI chat tools, RadixGPT retrieves only the most relevant documents, displaying sources so you can validate answers.'
            },
            {
              title: 'Geospatial Intelligence in Every Query',
              subtitle: 'Answers aren’t just text — they are mapped geospatially, so you can visualize where the key geological insights are coming from.'
            }
          ]
        },
        {
          title: "RadixFinance",
          icon: BIconGraphUp,
          heading: "RadixFinance",
          image: "RadixFinance.webp",
          alt: "RadixFinance",
          details: [
            {
              title: 'Comprehensive Global Coverage',
              subtitle: 'Search decades of company announcements across multiple stock exchanges, all in one place.'
            },
            {
              title: 'Geospatial Intelligence',
              subtitle: 'Filter announcements by project location and visualize activity through heatmaps.'
            },
            {
              title: 'AI-Powered Alerts & Insights',
              subtitle: 'Stay ahead of the market with real-time notifications on critical developments.'
            }
          ]
        },
        {
          title: "RadixExtract",
          icon: BIconGrid,
          heading: "RadixExtract",
          image: "RadixExtract.webp",
          alt: "RadixExtract",
          details: [
            {
              title: 'Effortless Table Extraction',
              subtitle: 'Extract structured tables from images — even from messy, handwritten, or poorly formatted sources—without manual data entry.'
            },
            {
              title: 'AI-Powered Quality Control',
              subtitle: 'Ensure accuracy with confidence scores and an intuitive heatmap that pinpoints areas needing review.'
            },
            {
              title: 'Seamless Integration with Exploration Software',
              subtitle: 'Export directly to Excel or import extracted tables into your preferred geoscience tools for faster analysis and decision-making.'
            }
          ]
        },
        // {
        //   title: "Geospatial Visualizer",
        //   icon: BIconGlobe2,
        //   heading: "Geospatial Visualizer",
        //   image: "AssayFinder.webp",
        //   alt: "Geospatial Visualizer",
        // },
      ],
    };
  },
  // data() {
  //   return {
  //     activeTab: 0, // Default active tab
  //     tabs: [], // Store tab components
  //   };
  // },
  // created() {
  //   // Register child tabs
  //   this.tabs = this.$children;
  // },
  watch: {
    // Watch for changes in the query parameter and update active tab
    "$route.query.tabId"(newTabId) {
      this.updateActiveTab(newTabId);
    },
  },
  mounted() {
    // Set the initial active tab based on the URL query parameter
    this.updateActiveTab(this.$route.query.tabId);
  },
  methods: {
    updateActiveTab(tabId) {
      const tabIndex = parseInt(tabId, 10);
      if (typeof tabIndex === 'number') {
        this.activeTab = tabIndex;
      } else {
        this.activeTab = 0;
      }
    },
    setActiveTab(index) {
      this.activeTab = index;
      this.$router.push({ query: { tabId: index } });
    },
  },
};
</script>

<style>
.tabs-scroll {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.heading-features {
  color: #6d70a6;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1rem;
}

.heading-features-color {
  color: #6d70a6;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.features-img {
  max-height: 100%;
  max-width: 100%;
  box-shadow: 5px 5px 5px lightgray;
  border-radius: 10px;
}

.nav-tabs .nav-link {
  border-radius: 1rem;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.nav-tabs .nav-link.active {
  background-color: #3a4eca;
  color: #fff !important;
  border-color: #3a4eca;
}

.nav-tabs .nav-link:hover {
  background-color: rgba(58, 78, 202, 0.1);
}

.detail-item {
  background-color: #fff;
  margin-bottom: 0.5rem;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 10px 20px 5px rgba(89, 69, 230, 0.1);
}

.detail-item>h6 {
  color: #6d70a6;
  margin-bottom: 15px;
}

.tabs-scroll .nav-item {
  flex-grow: 1;
  text-align: center;
}
</style>