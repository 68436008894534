<template>
  <section class="text-center py-5 mb-4 overflow-hidden">
    <h4 class="fw-bold">Trusted by Leading Mining Companies</h4>
    <p class="text-muted mt-2">
      Join the growing number of companies using RadiXplore to accelerate their mineral exploration
    </p>

    <!-- Scrolling Container -->
    <div class="logo-slider">
      <div class="logo-track">
        <div v-for="(logo, index) in repeatedLogos" :key="index" class="logo-item">
          <img v-if="logo" :src="logo" :alt="'Company logo ' + (index + 1)" class="logo-img" />
          <span v-else class="text-secondary fs-3">+</span>
        </div>
      </div>
    </div>

    <p class="text-muted mt-3">
      Trusted by companies across Australia, Canada, and the Middle East
    </p>
  </section>
</template>

<script>
export default {
  name: "TrustedCompanies",
  data() {
    return {
      companyLogos: [
        require("../assets/img/Customers/Aldoro.webp"),
        require("../assets/img/Customers/BulletinResources.webp"),
        require("../assets/img/Customers/CalidusResources.webp"),
        require("../assets/img/Customers/CastleMinerals.webp"),
        require("../assets/img/Customers/DevexResporces.webp"),
        require("../assets/img/Customers/DMIRS.webp"),
        require("../assets/img/Customers/Fortescue.webp"),
        require("../assets/img/Customers/GibbRiverDiamond.webp"),
        require("../assets/img/Customers/ImpactMinerals.webp"),
        require("../assets/img/Customers/Ozminerals.webp"),
      ],
    };
  },
  computed: {
    repeatedLogos() {
      return [...this.companyLogos, ...this.companyLogos]; // Duplicate for smooth looping
    },
  },
};
</script>

<style scoped>
/* Wrapper for the scrolling effect */
.logo-slider {
  width: 100%;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
}

/* Scrolling animation */
.logo-track {
  display: flex;
  gap: 40px;
  animation: scrollLogos 25s linear infinite;
  align-items: center;
  width: max-content;
}

/* Individual logo container */
.logo-item {
  width: 125px;
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  flex-shrink: 0;
}

/* Logos are grayscale by default */
.logo-img {
  max-width: 100%;
  max-height: 100%;
  filter: grayscale(100%);
  transition: filter 0.3s ease-in-out;
}

/* Color appears on hover */
.logo-img:hover {
  filter: grayscale(0%);
}

/* Infinite scrolling animation */
@keyframes scrollLogos {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}
</style>
