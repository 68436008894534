<template>
  <div class="row">
    <div class="col-lg-1"></div>
    <div class="col-lg-10 video-popup video-link">
      <div>
        <div class="single-popup-wrap" @click="$bvModal.show('video-modal')">
          <div class="image-overlay"></div>
          <img
            class="img-fluid"
            src="../assets/img/other/app-screenshot.png"
            alt="Image"
          />
          <div class="ht-popup-video video-button video-overlay">
            <div class="video-button__one">
              <div class="video-play">
                <span class="video-play-icon"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-1"></div>
  </div>
</template>

<script>
export default {
  name: "PopUpVideoOne",
};
</script>

<style scoped>
.single-popup-wrap {
  /* border: 15px solid rgb(77, 77, 77); */
  border: 15px solid #151136;
  border-radius: 10px;
  /* width: 600px;
    height: 300px; */
  /* background: url('../assets/img/logo/logo-with-name-coloured.png') 30px 60px no-repeat; */
}

.single-popup-wrap:hover {
  cursor: pointer;
}


/* .video-play {
    background-color: rgba(0,0,0,0.6);
    z-index: 3;
}

.video-play-icon {
    color: black;
} */
</style>
