<template>
  <b-modal id="video-modal" size="lg" centered hide-footer>
        <iframe
          id="video-player"
          src="https://www.youtube.com/embed/m-0QBNYBNc8?si=CLxqG8Tkm8EpRY1f?autoplay=1"
          title="What is RadiXplore"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </b-modal>
</template>

<script>
export default {
  name: 'VideoModal'
}
</script>

<style scoped>
#video-player {
  width: 100%;
  height: 50vh;
  border-radius: 5px;
}
</style>