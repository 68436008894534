<template>
  <div>
    <Navbar class="is-sticky"/>
    <div class="background-image-container">
      <div class="background-text mt-5">Use AI to tap into the wisdom of past explorers</div>
    </div>
    <div v-if="blogPostsLoaded" class="blog-posts-section">
      <div class="tags-dropdown">
        <div class="search-container">
          <div class="btn-input-container">
            <b-button @click="toggleSearch" class="search-btn">
              <i class="fa fa-search"></i>
            </b-button>
            <b-form-input v-if="showSearch"
              v-model="searchQuery"
              placeholder="Search posts..."
              class="search-input"
              @input="filterBySearch"
            ></b-form-input>
          </div>
        </div>
        <b-form-select v-model="selectedTag" @change="filterByTag">
          <b-form-select-option value="All">All</b-form-select-option>
          <b-form-select-option v-for="tag in availableTags" :key="tag.id" :value="tag.name">
            {{ tag.name }}
          </b-form-select-option>
        </b-form-select>
      </div>
      <div class="blog-cards">
        <div v-if="filteredPosts.length === 0">No posts available</div>
        <div v-for="(post) in filteredPosts" :key="post.slug" :class="['blog-card']" @click="openBlogPost(post.slug)">
          <!-- <div class="image-container"> -->
            <img :src="post.feature_image" :alt="post.title" class="blog-card-image" />
          <!-- </div> -->
          <div class="pt-3">
            <span class="author-name">{{post.authors[0].name}}</span><br>
            <p class="date-label">{{formatDate(post.published_at)}}</p>
            <div class="blog-card-title">{{ post.title }}</div>
            <span class="excerpt">{{post.excerpt}}</span>
          </div>
        </div>
      </div>
    </div>
    <FooterMain v-if="blogPostsLoaded" FooterStyle="bg_drak-footer"/>
  </div>
</template>

<script>
import axios from 'axios';
import Navbar from '../components/Navbar';
import FooterMain from '@/components/FooterMain';

export default {
  name: 'Blog',
  components: {
    Navbar,
    FooterMain
  },
  data() {
    return {
      blogPosts: [],
      filteredPosts: [],
      availableTags: [],
      selectedTag: 'All',
      blogPostsLoaded: false,
      searchQuery: '',
      showSearch: false,
    };
  },
  created() {
    this.fetchBlogPosts();
  },
  methods: {
    async fetchBlogPosts() {
      try {
        const response = await axios.get('https://blog.radixplore.com/ghost/api/v3/content/posts/?key=35676c260a882c10ce47ef3411', {
          params: {
            include: 'tags,authors'
          }
        });
        this.blogPosts = response.data.posts;
        this.filteredPosts = this.blogPosts;
        this.extractAvailableTags();
        this.blogPostsLoaded = true;
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    },
    extractAvailableTags() {
      const tagsMap = {};
      this.blogPosts.forEach((post) => {
        post.tags.forEach((tag) => {
          if (!tagsMap[tag.id]) {
            tagsMap[tag.id] = tag;
          }
        });
      });
      this.availableTags = Object.values(tagsMap);
    },
    filterByTag() {
      this.applyFilters();
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
      if (!this.showSearch) {
        this.searchQuery = ''; // Clear the search query when hiding the search input
        this.applyFilters(); // Reset the filtered posts
      }
    },
    filterBySearch() {
      this.applyFilters();
    },
    applyFilters() {
      let filtered = this.blogPosts;
      
      if (this.selectedTag !== 'All') {
        filtered = filtered.filter((post) =>
          post.tags.some((tag) => tag.name === this.selectedTag)
        );
      }
      
      if (this.searchQuery) {
        filtered = filtered.filter((post) =>
          post.title.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
      
      this.filteredPosts = filtered;
    },
    openBlogPost(slug) {
      this.$router.push({ name: 'BlogPost', params: { slug } });
    },
    formatDate(dateString) {
      const options = { month: 'short', day: '2-digit', year: 'numeric' };
      const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
      return formattedDate;
    }
  },
};
</script>

<style>
.background-image-container {
  background-image: url('https://images.unsplash.com/photo-1483982258113-b72862e6cff6?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-size: cover;
  background-position: center;
  text-align: center;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.background-text {
  font-size: 36px;
  color: white;
}

.tags-dropdown {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

.tags-dropdown select {
  width: 200px;
  margin-left: auto;
}

.blog-posts-section {
  background: #ffffff;
}

.blog-cards {
  margin: 0 8%;
  padding: 3rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

@media (min-width: 600px) {
  .blog-cards {
    margin: 0 10%;
  }
}

@media (min-width: 1024px) {
  .blog-cards {
    margin: 0 15%;
  }
}

.blog-card {
  max-width: 350px;
  margin-bottom: 50px;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  padding: 10px;
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.blog-card:last-child {
  margin-right: 0; 
}

.blog-card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  position: relative;
}

.blog-card-title {
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.excerpt {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}

.author-name {
  font-size: smaller;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
}

.date-label {
  font-size: smaller;
  font-family: 'Raleway', sans-serif;
  font-weight: 100;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.btn-input-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 60%;
  margin: 0 0 0 200px;
}

.search-btn {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.search-btn:hover {
  background-color: #1426c8;
}

.search-input {
  margin-left: 1rem;
  transition: width 0.3s ease;
  width: 90%;
}
</style>
